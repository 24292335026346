@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
  box-sizing: border-box;
}

.react-flow__attribution {
  display: none;
}
